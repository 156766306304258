import { createRouter, createWebHistory } from "vue-router";
import store from "../store/store.js";
import CasinoPage from "../views/CasinoPage.vue";
import PromotionPage from "../views/PromotionPage.vue";
import LiveCasinoPage from "../views/LiveCasinoPage.vue";
import OthersGamePage from '../views/OthersGamePage.vue';
// import ScratchCardsPage from '../views/ScratchCardsPage.vue';
import CrashGamesPage from '../views/CrashGamesPage.vue';
import RegistrationPage from "../views/RegistrationPage.vue";
import LoginPage from "../views/LoginPage.vue";
import RecoveryPasswordPage from "../views/RecoveryPasswordPage.vue";
import ContactPage from "../views/pages/ContactPage.vue";
import AffiliateProgramPage from "../views/pages/AffiliateProgramPage.vue";
import StaticPage from "../views/pages/StaticPage.vue";
import PrivacyPage from "../views/pages/PrivacyPage.vue";
import TermsAndConditionPage from "../views/pages/TermsAndConditionPage.vue";
import SecurityPage from "../views/pages/SecurityPage.vue";
import ResponsibleGamingPage from "../views/pages/ResponsibleGamingPage.vue";
import ImprintPage from "../views/pages/ImprintPage.vue";
import HelpAndSupportPage from "../views/pages/HelpAndSupportPage.vue";
import DashboardPage from "../views/DashboardPage.vue";
import GamePage from "../views/GamePage.vue";
import DepositPage from "../views/dashboardPages/DepositPage.vue";
import LimitsPage from "../views/dashboardPages/LimitsPage.vue";
import PasswordResetPage from "../views/PasswordResetPage.vue";
import PasswordsPage from "../views/dashboardPages/PasswordsPage.vue";
import PlayerInformationsPage from "../views/dashboardPages/PlayerInformationsPage.vue";
import PlayerDocumentsPage from "../views/dashboardPages/PlayerDocumentsPage.vue";
import ReportsPage from "../views/dashboardPages/ReportsPage.vue";
//import MessagesPage from "../views/dashboardPages/MessagesPage.vue";
import TransactionsPage from "../views/dashboardPages/TransactionsPage.vue";
import TransactionStatus from "../views/TransactionStatus.vue";
import NewsletterOptOut from "../views/NewsletterOptOut.vue";
import WithdrawPage from "../views/dashboardPages/WithdrawPage.vue";
import KycPage from "../views/dashboardPages/KycPage.vue";
import SowqPage from "../views/dashboardPages/SowqPage.vue";
import BonusPage from "../views/dashboardPages/BonusPage.vue"
import KajotPrimeGamesPage from '../views/KajotPrimeGamesPage.vue'
import LoginPageAdmin from "../views/LoginPageAdmin.vue"
import MaintenancePage from "../views/MaintenancePage.vue"
import ChangePasswordImportPlayer from '../views/pages/ChangePasswordImportPlayer.vue'

const routes = [
  {
    path: "/:lang?/",
    name: "CasinoPage",
    component: CasinoPage,
  },
  {
    path: "/:lang?/prime",
    name: "kajotPrimePage",
    component: KajotPrimeGamesPage,
  },
  {
    path: "/:lang?/casino",
    name: "casino",
    component: CasinoPage
  },
  {
    path: "/:lang?/live-casino",
    name: "live-casino",
    component: LiveCasinoPage,
  },
  // {
  // 	path: '/bingo',
  // 	name: 'bingo',
  // 	component: BingoPage,
  // },
  {
    path: '/:lang?/others',
    name: 'others',
    component: OthersGamePage,
  },
  // {
  // 	path: '/scratch-cards',
  // 	name: 'scratch-cards',
  // 	component: ScratchCardsPage,
  // },
  {
    path: '/:lang?/crash-games',
    name: 'crash-games',
    component: CrashGamesPage,
  },
  {
    path: "/newsletter/opt-out",
    name: "newsletterOptOut",
    component: NewsletterOptOut,
  },
  {
    path: "/:lang?/registration/:temp_token?",
    name: "registration",
    component: RegistrationPage,
    meta: { restrictedPage: false },
  },
  {
    path: "/:lang?/login",
    name: "login",
    component: LoginPage,
    meta: { restrictedPage: false },
  },
  {
    path: "/:lang?/recovery-password",
    name: "recovery-password",
    component: RecoveryPasswordPage,
    meta: { restrictedPage: false },
  },
  {
    path: "/password/reset/:temp_token",
    name: "password-reset",
    component: PasswordResetPage,
    meta: { restrictedPage: false },
  },
  {
    path: "/:lang?/dashboard",
    name: "dashboard",
    component: DashboardPage,
    meta: { restrictedPage: true },
    children: [
      { path: "/:lang?/deposit", name: "deposit", component: DepositPage },
      { path: "/:lang?/limits", name: "limits", component: LimitsPage },
      {
        path: "/:lang?/passwords",
        name: "passwords",
        component: PasswordsPage,
      },
      {
        path: "/:lang?/informations",
        name: "informations",
        component: PlayerInformationsPage,
      },
      {
        path: "/:lang?/documents",
        name: "documents",
        component: PlayerDocumentsPage,
      },
      {
        path: "/:lang?/kyc",
        name: "kyc",
        component: KycPage,
      },
      {
        path: "/:lang?/sowq",
        name: "sowq",
        component: SowqPage,
      },
      {
        path: "/:lang?/reports",
        name: "reports",
        component: ReportsPage,
      },
   /*   {
        path: "/:lang?/messages",
        name: "messages",
        component: MessagesPage,
      }, */
      {
        path: "/:lang?/transactions",
        name: "transactions",
        component: TransactionsPage,
      },
      { path: "/:lang?/withdraw", name: "withdraw", component: WithdrawPage },
      {
        path: "/:lang?/myBonus",
        name: "myBonus",
        component: BonusPage,
      },
    ],
  },
  {
    path: "/game/play/:providerId/:gameId",
    props: true,
    name: "game-page",
    component: GamePage,
  },
  { path: "/:lang?/contact", name: "contact", component: ContactPage },
  {
    path: "/:lang?/affiliate-program",
    name: "affiliate-program",
    component: AffiliateProgramPage,
  },
  {
    path: "/:lang?/privacy",
    name: "privacy",
    component: PrivacyPage,
  },
  {
    path: "/:lang?/imprint",
    name: "imprint",
    component: ImprintPage,
  },
  {
    path: "/:lang?/terms-and-conditions",
    name: "terms-and-conditions",
    component: TermsAndConditionPage,
  },
  {
    path: "/:lang?/security",
    name: "security",
    component: SecurityPage,
  },
  {
    path: "/:lang?/responsible-gaming",
    name: "responsible-gaming",
    component: ResponsibleGamingPage,
  },
  {
    path: "/:lang?/help",
    name: "help",
    component: HelpAndSupportPage,
  },
  {
    path: "/:lang?/promotion/:promotionId?",
    name: "promotion",
    component: PromotionPage,
  },
  {
    path: "/:lang?/transactions/:transactionId",
    name: "transactionStatus",
    component: TransactionStatus,
  },
  {
    path: "/:lang?/:page",
    name: "staticPage",
    component: StaticPage,
  },
  {
    path: "/kajot-prime_access_for_admin",
    name: "LoginPageAdmin",
    component: LoginPageAdmin,
  },
  {
    path: "/:NotValid(.*)",
    redirect: "/",
  },
  {
    path: "/:lang?/in-maintenance",
    name: "MaintenancePage",
    component: MaintenancePage
  },
  {
    path: "/:lang?/changePassword",
    name: "ChangePasswordImportPlayer",
    component: ChangePasswordImportPlayer
  }

];

const scrollBehavior = (to, from, savedPosition) => {
  if (from.matched[0] == undefined) {
    return;
  }
  if (from.matched[0].path == to.matched[0].path) {
    return;
  }
  if (savedPosition) {
    return;
  }
  return { left: 0, top: 0 };
};

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior,
});

router.beforeEach((to, from, next) => {
  if (to.name !== 'MaintenancePage' && store.state.skinSettings.maintenance)
    next({ name: 'MaintenancePage' });
  else next();
});

export default router;
